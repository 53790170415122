import React from 'react';
import {Link} from 'react-router-dom';

const Hero1 = () => {
  return (
    <div className="hero__1">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero__left space-y-20">
              <h1 className="hero__title">
               Descubre el arte digital y las colecciones co-creadas por la comunidad de emprendedores de alto impacto
              </h1>
              <p className="hero__text txt">
                Realtok Marketplace es un mercado de NFT's construido por y para la comunidad con la idea de democratizar 
                el acceso a proyectos con economias compartidas como los colivings, coworkings entre otros.
              </p>
              <div
                className="space-x-20 d-flex flex-column flex-md-row
							sm:space-y-20">
                {/* <Link className="btn btn-primary" to="marketplace">
                  Ver mercado
                </Link> */}
                <Link className="btn btn-lg btn-primary" to="upload">
                  Crear
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              className="img-fluid w-full"
              id="img_js"
              src="img/bg/portadaMarketplace.png"
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero1;
